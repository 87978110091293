import React, { useCallback, useEffect, useState } from "react"
import {
  DataGrid,
  GridToolbar,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  gridPageSelector
} from "@mui/x-data-grid"
import { useDispatch } from "react-redux"
import Layout from "../../layout"
import { deleteUser, exportCsv, usersData,membership,cancelMembership } from "../../redux/slices/users"
import Loader from "../../components/loader"
import moment from "moment"
import ActionDialogs from "../../components/modal"
import { AiFillDelete } from "react-icons/ai"
import ExportToCSV from "../../components/export"
import { Button } from "@mui/material"
import Pagination from "@mui/material/Pagination"
import { BiEdit } from "react-icons/bi"
import axios from "axios"
import AboutMeText from "../../components/modal/aboutMe"
import authHeader from "../../services/auth-header"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export default function UserTable() {
  const [modalOpen, setModalOpen] = useState(false)
  const [aboutModalOpen, setAboutModalOpen] = useState(false)
  const [dobModalOpen, setDobModalOpen] = useState(false)
  const [changeData,setChangeData]=useState({});
  const [filterRequest, setFilterRequest] = useState({})
  const dispatch = useDispatch()
  const [userData, setUserData] = useState([])
  const [loading, setLoading] = useState(false)
  const [exportToCsv, setExportToCsv] = useState([])
  const [myId,setMyId]=useState();
  const [notificationData, setNotificationData] = useState({})
  const [notificationPopup, setNotificationPopup] = useState(false)
  function CustomPagination() {
    const apiRef = useGridApiContext()
    const page = useGridSelector(apiRef, gridPageSelector)
    const pageCount = useGridSelector(apiRef, gridPageCountSelector)

    return (
      <Pagination
        color='primary'
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    )
  }
  const handleUpgrade=(id)=>{
    dispatch(membership(id))
    .unwrap()
    .then(res => {
      if (res.users.status === 200) {
        fetchUsersData()
      }
    })
    .catch(() => {
      setLoading(false)
    })
  }

const handleDegrade=(id)=>{
  dispatch(cancelMembership(id))
    .unwrap()
    .then(res => {
      if (res.users.status === 200) {
        fetchUsersData()
      }
    })
    .catch(() => {
      setLoading(false)
    })
}

  const columns = [
    { field: "name", headerName: "Name", width: 130, renderCell:params=>{
      return <>
        <BiEdit
              onClick={e => {
               setChangeData(params.row)
               setAboutModalOpen(true)
              }}
              className='icon'
              style={{
                color: "green",
                backgroundColor: "white",
                fontSize: "20px",
                padding: "10px"
              }}
            />
            {params.value}
      </>
    } },
    { field: "uid", headerName: "UID", width: 300 },
    { field: "mobile", headerName: "Contact", width: 130 },
    { field: "", headerName: "Notifications", width: 140,  renderCell: params => {
      return <Button variant="outlined" onClick={()=>{
        getNotifications(params.row._id);
      }}>Get</Button>
    }},
    {field:"isNotification",headerName:"Notification Status",width:200,renderCell: params => {
      // console.log(params.value,"Hi?")
      return params?.value ? "Enabled" : "Disabled";
    }},
    { field: "gender", headerName: "Gender", width: 100 },
    {
      field: "dob",
      headerName: "DOB",
      flex: 0,
      width: 200,
      renderCell: params => {
        return <>
          <BiEdit
              onClick={e => {
                setChangeData(params.row)
                setDobModalOpen(true)
              }}
              className='icon'
              style={{
                color: "green",
                backgroundColor: "white",
                fontSize: "20px",
                padding: "10px"
              }}
            />
          {moment(params.value).format("DD-MM-YYYY")}
        </>
      }
    },
    { field: "skin", headerName: "Skin Condition", width: 150 },
    { field: "country", headerName: "Country", width: 140 },
    { field: "paid", headerName: "Membership", width: 140,  renderCell: params => {
      if(params.row.paid===true)
      // return <p>Ugraded</p>
      return <Button variant="outlined" onClick={()=>{
        handleDegrade(params.row._id);
      }}>Cancel</Button>
      else
      return <Button variant="outlined" onClick={()=>{
        handleUpgrade(params.row._id);
      }}>Upgrade</Button>
    }},
      

    { field: "subscriptionExpiry", headerName: "Validity", width: 130,renderCell:params=>{
      if(params.value===null)
      return ("N/A");
      else
      return moment(params.value).format("DD-MM-YYYY")
    } },
    {
      field: "updatedAt",
      headerName: "Last Login",
      flex: 0,
      minWidth: 130,
      renderCell: params => {
        return moment(params.value).format("DD-MM-YYYY")
      }
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: params => {
        const onDelete = e => {
          setFilterRequest({ id: params.row._id, status: "accepted",handler: "both" })
          modalOpen ? setModalOpen(false) : setModalOpen(true)
        }

        return (
          <div>
            <AiFillDelete
              onClick={()=>{
                console.log(params.row._id,"here is my selected id");
                setMyId(params.row._id);
                onDelete();
              }}
              className='icon'
              style={{
                color: "red",
                fontSize: "20px",
                backgroundColor: "white",
                padding: "10px"
              }}
            />
          </div>
        )
      }
    }
  ]


  const fetchUsersData = () => {
    dispatch(usersData())
      .unwrap()
      .then(res => {
        console.log(res.users.data.users);
        let arr=[];
        res.users.data.users.map((item,index)=>{
              if(item.isDeleted===false)
              arr.push(item);
        })
        setUserData(arr);
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const fetchCSVData = () => {
    dispatch(exportCsv())
      .unwrap()
      .then(res => {
        
        setExportToCsv(res?.users?.data?.userData)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    setLoading(true)
    fetchCSVData()
  }, [])

  useEffect(() => {
    setLoading(true)
    fetchUsersData()
  }, [])

  const handleYesButton = () => {
    dispatch(deleteUser({id:myId, data:filterRequest}))
      .unwrap()
      .then(res => {
        if (res.users.status === 200) {
          fetchUsersData()
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
    setModalOpen(false)
  }

   const handleOkButton = async() => {
     axios.post("https://api.dermacupid.com/api/v1/admin/edit-name",{name:changeData.name,_id:changeData._id},{ headers: authHeader() }).then((res)=>{
      toast.success("Name updated successfully!")
      
      setAboutModalOpen(false)
      fetchUsersData()
     }).catch((err)=>{
       toast.error("Something went wrong!")
     })
  }

  const handleDobOkButton = async() => {
    let formattedDate = moment(changeData.dob, "DD-MM-YYYY").format();
    
     axios.post("https://api.dermacupid.com/api/v1/admin/edit-dob",{dob:formattedDate,_id:changeData._id},{ headers: authHeader() }).then((res)=>{
      toast.success("Dob updated successfully!")
      setDobModalOpen(false)
      fetchUsersData()
     }).catch((err)=>{
       toast.error("Something went wrong!")
     })
  }

  const handleAboutChange = (e) => {
    if(e.target.value.trim().length>1){
      setChangeData({...changeData,name:e.target.value})
    }
  }

  const handleDobChange = (e) => {
    if(e.target.value.trim().length>1){
      setChangeData({...changeData,dob:e.target.value})
    }
  }

  const getNotifications = (id)=>{
    axios.get("https://api.dermacupid.com/api/v1/admin/getUserNotifications/"+id,{ headers: authHeader() }).then((res)=>{
      if(res.data.status=="Success"){
        
        setNotificationData(res.data.data)
       
        setNotificationPopup(true)
        

      }
    })
  }


  return (
    <Layout>
     <ToastContainer />
      {loading ? (
        <Loader />
      ) : (
        <div style={{ height: "75vh", width: "100%" }}>
          <h1 style={{ display: "flex", justifyContent: "start" , marginBottom:'0.8rem' , marginBottom:'0.8rem' }}>User</h1>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem"
            }}
          >
           {exportToCsv && exportToCsv.length &&  <Button variant='outlined'>
            <ExportToCSV data={exportToCsv} />
            </Button>}
          </div>

          <DataGrid
            rows={userData && userData.length ? userData : []}
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[15]}
            getRowId={row => row._id}
            components={{ Toolbar: GridToolbar, Pagination: CustomPagination }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
                quickFilterProps: { debounceMs: 500 }
              }
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
          {modalOpen && (
            <ActionDialogs
              ModalTitle={"User table"}
              modalBody='The user will be logged out of the app immediately and he/she will not be able to use the app further. You have to go to delete requests page to fulfill this request'
              handleYesButton={handleYesButton}
              handler={filterRequest?.handler}
              titleShow={true}
            />
          )}
          {aboutModalOpen && (
            <AboutMeText
              ModalTitle={"Change Name"}
              AboutMeText={changeData.name}
              handleChange={handleAboutChange}
              handleOkButton={handleOkButton}
              onClose={() => setAboutModalOpen(false)}
            />
          )}
          {dobModalOpen && (
            <AboutMeText
              ModalTitle={"Change DOB"}
              AboutMeText={moment(changeData.dob).format("DD-MM-YYYY")}
              handleChange={handleDobChange}
              handleOkButton={handleDobOkButton}
              onClose={() => setDobModalOpen(false)}
            />
          )}
          {
            notificationPopup&&(
              <AboutMeText
              ModalTitle={"Notifications"}
              AboutMeText={`Regular Likes: ${notificationData.regularLikes} \nFiltered Out Likes: ${notificationData.filteredOutLikes} \nRegular Chat Request: ${notificationData.regularReqs} \nFiltered Chat Request: ${notificationData.filteredOutReqs} \nMessages: ${notificationData.messageCount}`}
              onClose={() => setNotificationPopup(false)}
              handleOkButton={() => setNotificationPopup(false)}
              ></AboutMeText>
            )
          }
        </div>
      )}
    </Layout>
  )
}
